@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}

.color-red {
  color: #FF6767;
}

.color-yellow {
  color: #FFB800;
}

.color-green {
  color: #88F976;
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.d-flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-flex-start {
  display: flex;
  align-items: center;
  justify-content: start;
}

.d-grid-center {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
}

.d-flex-end {
  display: flex;
  align-items: center;
  justify-content: end;
}

.p-color-green-two {
  color: rgb(113, 207, 98) !important;
  font-size: 10px;
}

.p-color-red-two {
  color: rgb(251, 98, 98) !important;
  font-size: 10px;
}

.p-color-yellow {
  color: #FFB800;
  font-size: 13px;
}

.p-color-blue {
  color: #00B7D4;
  font-size: 13px;
}

.p-color-green {
  color: rgb(113, 207, 98) !important;
  font-size: 13px;
}

.collapse_p {
  font-weight: bold;
}

.p-color-red {
  color: rgb(251, 98, 98) !important;
  font-size: 13px;
}

.text-center {
  text-align: center;
}

.border-red {
  border: 2px solid rgb(251, 98, 98) !important;
}

.Modal-btn {
  font-size: 14px !important;
  line-height: 24px !important;
  padding: 10px 50px !important;
  margin-right: 20px !important;
  color: black;
  border: none;
  border-radius: 50px;
}

.Modal-btn-red {
  background-color: #E33D58;
  color: white !important;
}


.frame {

  font-family: sans-serif;
  overflow: hidden;


  display: inline-block;
  cursor: all-scroll
}

.zoom {
  font-size: 1.3vw;
  transition: transform 0.2s linear;
  cursor: all-scroll
}

.lorem {
  padding: 2% 2%;
}

.displayblock {
  display: block !important;
}

.displaynone {
  display: none !important;
}

.color-white {
  color: white !important;
  border: 1px solid white !important;
}

.opendoor_btn_green {
  background-color: rgb(113, 207, 98) !important;
}